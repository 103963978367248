/**
 * Serializes an object to a list of query parameters.
 * This function is necessary because default behavior for Redux Toolkit is to
 * serialize an array to a list of comma-separated values, which is not the desired behavior.
 * @param data{Object}
 * @returns {URLSearchParams}
 */
/* eslint-disable */

export const objectToQueryParams = (data) => {
    const params = new URLSearchParams()

    Object.entries(data)
        .filter(([key, value]) => value !== null && value !== undefined)
        .forEach(([key, value]) => {
            if (typeof value === 'object' && !Array.isArray(value)) {
                return console.error(`Nested objects are not allowed. Skipping the ${key} param.`)
            }

            if (Array.isArray(value)) {
                value.forEach((param) => params.append(key, param))
            } else {
                params.append(key, value)
            }
        })

    return params
}

export const JSONToUrlEncoded = (element, key, l) => {
    let list = l || []
    if (typeof element == 'object') {
        for (let idx in element)
            JSONToUrlEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list)
    } else {
        list.push(key + '=' + encodeURIComponent(element))
    }
    return list.join('&')
}

export const isURLValid = (str) =>
    !!/^(https?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
        str,
    )
