import { createSlice } from '@reduxjs/toolkit'
import { api } from '../apiSlice'
import TAGS from '../cacheTags'
import { objectToQueryParams } from '@/utils/url-utils'

const extendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAll: build.query({
            query: (params) => ({
                url: `users/`,
                params: objectToQueryParams({
                    ...params,
                }),
            }),
            providesTags: [TAGS.users],
        }),
        createUser: build.mutation({
            query: (data) => ({
                url: `users/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.users],
        }),
        updateUser: build.mutation({
            query: ({ id, ...data }) => ({
                url: `users/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [TAGS.users],
        }),
    }),
})

const userSlice = createSlice({
    name: 'user',
    initialState: {
        editUser: null,
    },
    reducers: {
        setEditUser(state, action) {
            // eslint-disable-next-line no-param-reassign
            state.editUser = action.payload
        },
    },
})

export const { useGetAllQuery, useUpdateUserMutation, useCreateUserMutation } = extendedApi
export const { setEditUser } = userSlice.actions
export const { reducer } = userSlice
