import { SvgIcon } from '@mui/material'
import * as React from 'react'

const Gear = () => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_130_83)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 13.5C11.933 13.5 13.5 11.933 13.5 10C13.5 8.067 11.933 6.5 10 6.5C8.067 6.5 6.5 8.067 6.5 10C6.5 11.933 8.067 13.5 10 13.5ZM12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.807617 10L3.5 7.30762V3.50001H7.30762L10 0.807617L12.6924 3.50001H16.5V7.30761L19.1924 10L16.5 12.6924V16.5H12.6924L10 19.1924L7.30762 16.5H3.5V12.6924L0.807617 10ZM5 7.92894V5.00001H7.92894L10 2.92894L12.0711 5.00001H15V7.92893L17.0711 10L15 12.0711V15H12.0711L10 17.0711L7.92894 15H5V12.0711L2.92894 10L5 7.92894Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_130_83">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </SvgIcon>
)

export default Gear
