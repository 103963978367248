import useAuth from '@/hooks/useAuth'
import BoxCircularProgress from '@/components/common/BoxCircularProgress'
import useBrandSelect from '@/hooks/useBrandSelect'
import useFeatureFlags from '@/hooks/useFeatureFlags'

export default function BrandLayout(children) {
    const { isFetching, user } = useAuth({ middleware: 'auth' })
    const { currentBrand } = useBrandSelect()
    const { featureFlags } = useFeatureFlags()

    if (isFetching || !currentBrand || !user || !featureFlags) {
        return <BoxCircularProgress />
    }

    return children
}
