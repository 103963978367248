import * as React from 'react'
import { SvgIcon } from '@mui/material'

const PriceTag = () => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.00008 9C10.1047 9 11.0001 8.10456 11.0001 7C11.0001 5.89543 10.1047 5 9.00008 5C7.89552 5 7.00009 5.89543 7.00009 7C7.00009 8.10456 7.89552 9 9.00008 9Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.98682 18.2452L2.98682 7.8529L4.51791 3.50482L7.98201 1.50482L12.5131 2.3529L18.5131 12.7452L8.98682 18.2452ZM9.57246 16.0596L4.7404 7.6902L5.83049 4.59452L8.26942 3.1864L11.4954 3.7902L16.3275 12.1596L9.57246 16.0596Z"
            fill="black"
        />
    </SvgIcon>
)

export default PriceTag
