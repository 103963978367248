import { SvgIcon } from '@mui/material'

const Building = () => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M4.5 7.5V4.5H8.5V7.5H4.5Z" fill="black" />
        <path d="M11.5 7.5V4.5H15.5V7.5H11.5Z" fill="black" />
        <path d="M4.5 10.5V13.5H8.5V10.5H4.5Z" fill="black" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5 18.5H1.5V1.5H18.5V18.5ZM3 17H11.5V10.5H15.5V17H17V3H3V17Z"
            fill="black"
        />
    </SvgIcon>
)

export default Building
