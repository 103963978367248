import Router from 'next/router'
import { setCurrentBrand } from '@/redux/slices/brandSlice'
import { api } from '@/redux/apiSlice'
import TAGS from '@/redux/cacheTags'
import { PATH_DASHBOARD, updatePaths } from '@/routes/paths'

const selectBrandMiddleware = (store) => (next) => (action) => {
    if (setCurrentBrand.match(action) && action.payload?.id) {
        const id = action?.payload?.id?.toString()?.toLowerCase()

        if (window?.location?.pathname !== PATH_DASHBOARD.rootWithoutId) {
            Router.replace({ query: { ...Router.query, brandId: id } })
        }

        updatePaths(id)

        // When switching brands, reset the whole API state
        if (action.payload?.resetAPIState) {
            store.dispatch(api.util.resetApiState())
        } else {
            // Otherwise only invalidate the cached tags
            store.dispatch(
                api.util.invalidateTags(Object.values(TAGS).filter((t) => t !== TAGS.brands)),
            )
        }
    }
    return next(action)
}

export default selectBrandMiddleware
