import { SvgIcon } from '@mui/material'

const Support = ({ sx }) => (
    <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        sx={sx}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 12.999C11.6563 12.999 12.999 11.6563 12.999 10C12.999 8.34369 11.6563 7.00098 10 7.00098C8.34369 7.00098 7.00098 8.34369 7.00098 10C7.00098 11.6563 8.34369 12.999 10 12.999ZM10 14.499C12.4847 14.499 14.499 12.4847 14.499 10C14.499 7.51526 12.4847 5.50098 10 5.50098C7.51526 5.50098 5.50098 7.51526 5.50098 10C5.50098 12.4847 7.51526 14.499 10 14.499Z"
            fill="black"
        />
        <path d="M8.25 2.5H11.75V6.5H8.25V2.5Z" fill="black" />
        <path d="M8.25 13.5H11.75V17.5H8.25V13.5Z" fill="black" />
        <path d="M2.5 11.75L2.5 8.25H6.5V11.75H2.5Z" fill="black" />
        <path d="M13.5 11.75V8.25H17.5V11.75H13.5Z" fill="black" />
    </SvgIcon>
)

export default Support
