import useAuth from '@/hooks/useAuth'
import { BRAND_ADMIN_CPA, BRAND_ADMIN_PPU } from '@/utils/enums/userRoles'

/* eslint-disable no-param-reassign */
const usePermissions = () => {
    const { user } = useAuth()

    const isSuperuser = () => !!user?.is_superuser

    const isGiddyUpStaff = () => !!user?.is_giddyup_staff

    const hasRole = (role) => isSuperuser() || !!user?.roles?.find((r) => r === role)

    const isBrandAdmin = () =>
        !!user?.roles?.find((r) => [BRAND_ADMIN_PPU, BRAND_ADMIN_CPA].includes(r))

    const hasProperty = (property) => isSuperuser() || !!user?.[property]

    const hasPermission = (requiredPermissions) => {
        if (!Array.isArray(requiredPermissions)) {
            requiredPermissions = [requiredPermissions]
        }
        return (
            isSuperuser() ||
            requiredPermissions.every(
                (permission) => !!user?.all_permissions.find((p) => p.codename_full === permission),
            )
        )
    }

    const hasSomePermission = (requiredPermissions = []) => {
        if (!Array.isArray(requiredPermissions)) {
            requiredPermissions = [requiredPermissions]
        }
        return (
            isSuperuser() ||
            !requiredPermissions.length ||
            requiredPermissions.some(
                (permission) => !!user?.all_permissions.find((p) => p.codename_full === permission),
            )
        )
    }

    const hasExactPermission = (requiredPermissions) => {
        if (!Array.isArray(requiredPermissions)) {
            requiredPermissions = [requiredPermissions]
        }
        return requiredPermissions.every(
            (permission) => !!user?.all_permissions.find((p) => p.codename_full === permission),
        )
    }

    return {
        isSuperuser,
        isGiddyUpStaff,
        isBrandAdmin,
        hasRole,
        hasProperty,
        hasPermission,
        hasSomePermission,
        hasExactPermission,
    }
}

export default usePermissions
