import { PROVIDER_CONFIGURATION, PROVIDERS } from '@/utils/enums/paymentProviders'

// Paypal
export const getPaypalProvider = (providers, currentBrand) =>
    providers?.results?.find?.(
        (provider) => provider?.type === 'paypal' && provider?.brand === currentBrand?.id,
    )

export const isPaypalAccountComplete = (providers, currentBrand) =>
    !!getPaypalProvider(providers, currentBrand)?.configurations?.find(
        (c) => c.key === PROVIDER_CONFIGURATION.MERCHANT_ID,
    )

// Stripe

export const getStripeProvider = (providers, currentBrand) =>
    providers?.results?.find?.(
        (provider) => provider?.type === 'stripe' && provider?.brand === currentBrand?.id,
    )

export const getStripeAccountNumber = (providers, currentBrand) =>
    getStripeProvider(providers, currentBrand)?.configurations?.find(
        (c) => c?.key === PROVIDER_CONFIGURATION.ACCOUNT,
    )?.value

const hasIncompleteConfiguration = (providers, currentBrand) =>
    !!getStripeProvider(providers, currentBrand)?.configurations?.find(
        (c) =>
            [PROVIDER_CONFIGURATION.ACCOUNT_INCOMPLETE].includes(c.key) &&
            [true, 'true'].includes(c.value),
    )

export const isStripeAccountComplete = (providers, currentBrand) =>
    getStripeAccountNumber(providers, currentBrand) &&
    !hasIncompleteConfiguration(providers, currentBrand)

export const hasStripePayoutFailedIssue = (stripeProvider) =>
    stripeProvider?.configurations?.find(
        (c) =>
            [PROVIDER_CONFIGURATION.PAYOUT_FAILED].includes(c.key) &&
            [true, 'true'].includes(c.value),
    )

export const isPaidInPayPal = (lastPayment) => {
    const { payment_method } = lastPayment || {}

    return payment_method === PROVIDERS.paypal.type
}

export const isPaidInStripe = (lastPayment) => {
    const { payment_method } = lastPayment || {}

    return payment_method === PROVIDERS.stripe.type
}
