export const AUTH_VIEW_GROUP = 'auth.view_group'
export const AUTH_VIEW_PERMISSION = 'auth.view_permission'

export const USERS_ADD_USER = 'users.add_user'
export const USERS_VIEW_USER = 'users.view_user'
export const USERS_CHANGE_USER = 'users.change_user'
export const USERS_DELETE_USER = 'users.delete_user'

export const BRANDS_ADD_BRAND = 'brands.add_brand'
export const BRANDS_CHANGE_BRAND = 'brands.change_brand'
export const BRANDS_DELETE_BRAND = 'brands.delete_brand'
export const BRANDS_VIEW_BRAND = 'brands.view_brand'
export const BRANDS_VIEW_ALL_BRAND = 'brands.view_all_brands'
export const BRANDS_CURRENCY_OVERRIDE_VIEW = 'brands.view_brandcurrencyoverride'
export const BRANDS_CURRENCY_OVERRIDE_CHANGE = 'brands.change_brandcurrencyoverride'

export const CATALOG_ADD_PRODUCT = 'catalog.add_product'
export const CATALOG_VIEW_PRODUCT = 'catalog.view_product'
export const CATALOG_CHANGE_PRODUCT = 'catalog.change_product'
export const CATALOG_DELETE_PRODUCT = 'catalog.delete_product'

export const CATALOG_ADD_BUNDLE = 'catalog.add_bundle'
export const CATALOG_VIEW_BUNDLE = 'catalog.view_bundle'
export const CATALOG_CHANGE_BUNDLE = 'catalog.change_bundle'
export const CATALOG_DELETE_BUNDLE = 'catalog.delete_bundle'

export const CATALOG_ADD_OFFER = 'catalog.add_offer'
export const CATALOG_VIEW_OFFER = 'catalog.view_offer'
export const CATALOG_CHANGE_OFFER = 'catalog.change_offer'
export const CATALOG_DELETE_OFFER = 'catalog.delete_offer'
export const CATALOG_MODIFY_NETWORK_COMMISSION = 'catalog.modify_network_commission'

export const DISCOUNTS_ADD_DISCOUNT_CODE = 'discounts.add_discountcode'
export const DISCOUNTS_VIEW_DISCOUNT_CODE = 'discounts.view_discountcode'
export const DISCOUNTS_CHANGE_DISCOUNT_CODE = 'discounts.change_discountcode'
export const DISCOUNTS_DELETE_DISCOUNT_CODE = 'discounts.delete_discountcode'

export const CATALOG_ADD_UPSELL = 'catalog.add_upsell'
export const CATALOG_VIEW_UPSELL = 'catalog.view_upsell'
export const CATALOG_CHANGE_UPSELL = 'catalog.change_upsell'
export const CATALOG_DELETE_UPSELL = 'catalog.delete_upsell'

export const ORDER_ADD_ORDER = 'orders.add_order'
export const ORDER_VIEW_ORDER = 'orders.view_order'
export const ORDER_CHANGE_ORDER = 'orders.change_order'
export const ORDER_DELETE_ORDER = 'orders.delete_order'
export const ORDER_VIEW_REPORT = 'orders.view_report'

export const PAYMENTS_ADD_PROVIDER = 'payments.add_paymentprovider'
export const PAYMENTS_VIEW_PROVIDER = 'payments.view_paymentprovider'
export const PAYMENTS_CHANGE_PROVIDER = 'payments.change_paymentprovider'
export const PAYMENTS_DELETE_PROVIDER = 'payments.delete_paymentprovider'

export const TAX_ADD_PROVIDER = 'tax.process_taxprovider'
export const TAX_VIEW_PROVIDER = 'tax.view_taxprovider'
export const TAX_CHANGE_PROVIDER = 'tax.change_taxprovider'
export const TAX_DELETE_PROVIDER = 'tax.delete_taxprovider'

export const TAX_ADD_BRANDTAXINFORMATION = 'tax.add_brandtaxinformation'
export const TAX_VIEW_BRANDTAXINFORMATION = 'tax.view_brandtaxinformation'
export const TAX_CHANGE_BRANDTAXINFORMATION = 'tax.change_brandtaxinformation'
export const TAX_DELETE_BRANDTAXINFORMATION = 'tax.delete_brandtaxinformation'

export const TAX_ADD_TAXREGION = 'tax.add_taxregion'
export const TAX_VIEW_TAXREGION = 'tax.view_taxregion'
export const TAX_CHANGE_TAXREGION = 'tax.change_taxregion'
export const TAX_DELETE_TAXREGION = 'tax.delete_taxregion'

export const FULFILLMENT_ADD_PROVIDER = 'fulfillment.add_fulfillmentprovider'
export const FULFILLMENT_VIEW_PROVIDER = 'fulfillment.view_fulfillmentprovider'
export const FULFILLMENT_CHANGE_PROVIDER = 'fulfillment.change_fulfillmentprovider'
export const FULFILLMENT_DELETE_PROVIDER = 'fulfillment.delete_fulfillmentprovider'

export const WAREHOUSES_ADD_PROVIDER = 'warehouses.add_warehouse'
export const WAREHOUSES_VIEW_PROVIDER = 'warehouses.view_warehouse'
export const WAREHOUSES_CHANGE_PROVIDER = 'warehouses.change_warehouse'
export const WAREHOUSES_DELETE_PROVIDER = 'warehouses.delete_warehouse'

export const MARKETING_EMAIL_ADD_PROVIDER = 'marketingemail.add_marketingemailprovider'
export const MARKETING_EMAIL_VIEW_PROVIDER = 'marketingemail.view_marketingemailprovider'
export const MARKETING_EMAIL_CHANGE_PROVIDER = 'marketingemail.change_marketingemailprovider'
export const MARKETING_EMAIL_DELETE_PROVIDER = 'marketingemail.delete_marketingemailprovider'

export const CMS_ADD_PROVIDER = 'cms.add_cmsprovider'
export const CMS_VIEW_PROVIDER = 'cms.view_cmsprovider'
export const CMS_CHANGE_PROVIDER = 'cms.change_cmsprovider'
export const CMS_DELETE_PROVIDER = 'cms.delete_cmsprovider'

export const CUSTOMER_SERVICE_ADD_PROVIDER = 'customerservice.add_customerserviceprovider'
export const CUSTOMER_SERVICE_VIEW_PROVIDER = 'customerservice.view_customerserviceprovider'
export const CUSTOMER_SERVICE_CHANGE_PROVIDER = 'customerservice.change_customerserviceprovider'
export const CUSTOMER_SERVICE_DELETE_PROVIDER = 'customerservice.delete_customerserviceprovider'

export const CUSTOMAPP_CHANGE_PROVIDER = 'customapps.change_customappprovider'
export const CUSTOMAPP_VIEW_ORDER = 'customapps.view_customapporder'
export const CUSTOMAPP_SHIPMENT_UPDATE = 'customapps.update_shipment'

export const MARKETING_PARTNER_MANAGE = 'operations.manage_marketing_partners'
