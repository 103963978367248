import * as React from 'react'
import { SvgIcon } from '@mui/material'

const ShoppingBag = () => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 6H18.5V18.5H1.5V6H6V1.5H14V6ZM7.5 7.5V10H6V7.5H3V17H17V7.5H14V10H12.5V7.5H7.5ZM7.5 6H12.5V3H7.5V6Z"
            fill="black"
        />
    </SvgIcon>
)

export default ShoppingBag
