export const currencies = {
    USD: {
        code: 'USD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        prefix: '$',
        suffix: '',
        currency_code_suffix: '',
        format: '$[amount]',
    },
    CAD: {
        code: 'CAD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        currency_code_suffix: 'CAD',
        format: '[amount]$ CAD',
    },
    EUR: {
        code: 'EUR',
        symbol: '€',
        decimal_mark: ',',
        thousands_separator: ' ',
        prefix: '',
        suffix: '€',
        currency_code_suffix: '',
        format: '[amount]€',
    },
    GBP: {
        code: 'GBP',
        symbol: '£',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '£[amount]',
    },
    JPY: {
        code: 'JPY',
        symbol: '¥',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '¥[amount]',
    },
    AUD: {
        code: 'AUD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        currency_code_suffix: 'AUD',
        format: '[amount]$ AUD',
    },
    NZD: {
        code: 'NZD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        currency_code_suffix: 'NZD',
        format: '[amount]$ NZD',
    },
    AED: {
        code: 'AED',
        symbol: 'د.إ.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'د.إ.‏[amount]',
    },
    AFN: {
        code: 'AFN',
        symbol: '؋',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '؋[amount]',
    },
    ALL: {
        code: 'ALL',
        symbol: 'Lek',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '[amount] Lek',
    },
    AMD: {
        code: 'AMD',
        symbol: '֏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] ֏',
    },
    ANG: {
        code: 'ANG',
        symbol: 'ƒ',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'ƒ[amount]',
    },
    AOA: {
        code: 'AOA',
        symbol: 'Kz',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'Kz[amount]',
    },
    ARS: {
        code: 'ARS',
        symbol: '$',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '$[amount]',
    },
    AWG: {
        code: 'AWG',
        symbol: 'ƒ',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'ƒ[amount]',
    },
    AZN: {
        code: 'AZN',
        symbol: '₼',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] ₼',
    },
    BAM: {
        code: 'BAM',
        symbol: 'КМ',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '[amount] КМ',
    },
    BBD: {
        code: 'BBD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    BDT: {
        code: 'BDT',
        symbol: '৳',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '৳[amount]',
    },
    BGN: {
        code: 'BGN',
        symbol: 'лв.',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] лв.',
    },
    BHD: {
        code: 'BHD',
        symbol: 'د.ب.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'د.ب.‏[amount]',
    },
    BIF: {
        code: 'BIF',
        symbol: 'FBu',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] FBu',
    },
    BMD: {
        code: 'BMD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    BND: {
        code: 'BND',
        symbol: '$',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '$[amount]',
    },
    BOB: {
        code: 'BOB',
        symbol: 'Bs',
        decimal_mark: ',',
        thousands_separator: '.',
        format: 'Bs[amount]',
    },
    BRL: {
        code: 'BRL',
        symbol: 'R$',
        decimal_mark: ',',
        thousands_separator: '.',
        format: 'R$[amount]',
    },
    BSD: {
        code: 'BSD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    BTC: {
        code: 'BTC',
        symbol: 'Ƀ',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] Ƀ',
    },
    BTN: {
        code: 'BTN',
        symbol: 'Nu.',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'Nu.[amount]',
    },
    BWP: {
        code: 'BWP',
        symbol: 'P',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'P[amount]',
    },
    BYR: {
        code: 'BYR',
        symbol: 'р.',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] р.',
    },
    BYN: {
        code: 'BYN',
        symbol: 'р.',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] р.',
    },
    BZD: {
        code: 'BZD',
        symbol: 'BZ$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'BZ$[amount]',
    },
    CDF: {
        code: 'CDF',
        symbol: 'FC',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] FC',
    },
    CHF: {
        code: 'CHF',
        symbol: 'CHF',
        decimal_mark: '.',
        thousands_separator: "'",
        format: 'CHF[amount]',
    },
    CLP: {
        code: 'CLP',
        symbol: '$',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '$[amount]',
    },
    CNY: {
        code: 'CNY',
        symbol: '¥',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '¥[amount]',
    },
    COP: {
        code: 'COP',
        symbol: '$',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '$[amount]',
    },
    CRC: {
        code: 'CRC',
        symbol: '₡',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '₡[amount]',
    },
    CUC: {
        code: 'CUC',
        symbol: 'CUC',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'CUC[amount]',
    },
    CUP: {
        code: 'CUP',
        symbol: '$MN',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$MN[amount]',
    },
    CVE: {
        code: 'CVE',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    CZK: {
        code: 'CZK',
        symbol: 'Kč',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] Kč',
    },
    DJF: {
        code: 'DJF',
        symbol: 'Fdj',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] Fdj',
    },
    DKK: {
        code: 'DKK',
        symbol: 'kr.',
        decimal_mark: ',',
        thousands_separator: '',
        format: '[amount] kr.',
    },
    DOP: {
        code: 'DOP',
        symbol: 'RD$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'RD$[amount]',
    },
    DZD: {
        code: 'DZD',
        symbol: 'د.ج.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'د.ج.‏[amount]',
    },
    EGP: {
        code: 'EGP',
        symbol: 'ج.م.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'ج.م.‏[amount]',
    },
    ERN: {
        code: 'ERN',
        symbol: 'Nfk',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] Nfk',
    },
    ETB: {
        code: 'ETB',
        symbol: 'ETB',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'ETB[amount]',
    },
    FJD: {
        code: 'FJD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    FKP: {
        code: 'FKP',
        symbol: '£',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '£[amount]',
    },
    GEL: {
        code: 'GEL',
        symbol: 'GEL',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] GEL',
    },
    GHS: {
        code: 'GHS',
        symbol: '₵',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₵[amount]',
    },
    GIP: {
        code: 'GIP',
        symbol: '£',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '£[amount]',
    },
    GMD: {
        code: 'GMD',
        symbol: 'D',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] D',
    },
    GNF: {
        code: 'GNF',
        symbol: 'FG',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] FG',
    },
    GTQ: {
        code: 'GTQ',
        symbol: 'Q',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'Q[amount]',
    },
    GYD: {
        code: 'GYD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    HKD: {
        code: 'HKD',
        symbol: 'HK$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'HK$[amount]',
    },
    HNL: {
        code: 'HNL',
        symbol: 'L.',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'L.[amount]',
    },
    HRK: {
        code: 'HRK',
        symbol: 'kn',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '[amount] kn',
    },
    HTG: {
        code: 'HTG',
        symbol: 'G',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'G[amount]',
    },
    HUF: {
        code: 'HUF',
        symbol: 'Ft',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] Ft',
    },
    IDR: {
        code: 'IDR',
        symbol: 'Rp',
        decimal_mark: ',',
        thousands_separator: '.',
        format: 'Rp[amount]',
    },
    ILS: {
        code: 'ILS',
        symbol: '₪',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₪[amount]',
    },
    INR: {
        code: 'INR',
        symbol: '₹',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₹[amount]',
    },
    IQD: {
        code: 'IQD',
        symbol: 'د.ع.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'د.ع.‏[amount]',
    },
    IRR: {
        code: 'IRR',
        symbol: '﷼',
        decimal_mark: '/',
        thousands_separator: ',',
        format: '﷼[amount]',
    },
    ISK: {
        code: 'ISK',
        symbol: 'kr.',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '[amount] kr.',
    },
    JMD: {
        code: 'JMD',
        symbol: 'J$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'J$[amount]',
    },
    JOD: {
        code: 'JOD',
        symbol: 'د.ا.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'د.ا.‏[amount]',
    },
    KES: {
        code: 'KES',
        symbol: 'KSh',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'KSh[amount]',
    },
    KGS: {
        code: 'KGS',
        symbol: 'сом',
        decimal_mark: '-',
        thousands_separator: ' ',
        format: '[amount] сом',
    },
    KHR: {
        code: 'KHR',
        symbol: '៛',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] ៛',
    },
    KMF: {
        code: 'KMF',
        symbol: 'CF',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] CF',
    },
    KPW: {
        code: 'KPW',
        symbol: '₩',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₩[amount]',
    },
    KRW: {
        code: 'KRW',
        symbol: '₩',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₩[amount]',
    },
    KWD: {
        code: 'KWD',
        symbol: 'د.ك.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'د.ك.‏[amount]',
    },
    KYD: {
        code: 'KYD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    KZT: {
        code: 'KZT',
        symbol: '₸',
        decimal_mark: '-',
        thousands_separator: ' ',
        format: '₸[amount]',
    },
    LAK: {
        code: 'LAK',
        symbol: '₭',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] ₭',
    },
    LBP: {
        code: 'LBP',
        symbol: 'ل.ل.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'ل.ل.‏[amount]',
    },
    LKR: {
        code: 'LKR',
        symbol: '₨',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₨[amount]',
    },
    LRD: {
        code: 'LRD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    LSL: {
        code: 'LSL',
        symbol: 'M',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] M',
    },
    LYD: {
        code: 'LYD',
        symbol: 'د.ل.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'د.ل.‏[amount]',
    },
    MAD: {
        code: 'MAD',
        symbol: 'د.م.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'د.م.‏[amount]',
    },
    MDL: {
        code: 'MDL',
        symbol: 'lei',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] lei',
    },
    MGA: {
        code: 'MGA',
        symbol: 'Ar',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'Ar[amount]',
    },
    MKD: {
        code: 'MKD',
        symbol: 'ден.',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '[amount] ден.',
    },
    MMK: {
        code: 'MMK',
        symbol: 'K',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'K[amount]',
    },
    MNT: {
        code: 'MNT',
        symbol: '₮',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '₮[amount]',
    },
    MOP: {
        code: 'MOP',
        symbol: 'MOP$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'MOP$[amount]',
    },
    MRO: {
        code: 'MRO',
        symbol: 'UM',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] UM',
    },
    MTL: {
        code: 'MTL',
        symbol: '₤',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₤[amount]',
    },
    MUR: {
        code: 'MUR',
        symbol: '₨',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₨[amount]',
    },
    MVR: {
        code: 'MVR',
        symbol: 'MVR',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] MVR',
    },
    MWK: {
        code: 'MWK',
        symbol: 'MK',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'MK[amount]',
    },
    MXN: {
        code: 'MXN',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    MYR: {
        code: 'MYR',
        symbol: 'RM',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'RM[amount]',
    },
    MZN: {
        code: 'MZN',
        symbol: 'MT',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'MT[amount]',
    },
    NAD: {
        code: 'NAD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    NGN: {
        code: 'NGN',
        symbol: '₦',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₦[amount]',
    },
    NIO: {
        code: 'NIO',
        symbol: 'C$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'C$[amount]',
    },
    NOK: {
        code: 'NOK',
        symbol: 'kr',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: 'kr[amount]',
    },
    NPR: {
        code: 'NPR',
        symbol: '₨',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₨[amount]',
    },
    OMR: {
        code: 'OMR',
        symbol: '﷼',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '﷼[amount]',
    },
    PAB: {
        code: 'PAB',
        symbol: 'B/.',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'B/.[amount]',
    },
    PEN: {
        code: 'PEN',
        symbol: 'S/.',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'S/.[amount]',
    },
    PGK: {
        code: 'PGK',
        symbol: 'K',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'K[amount]',
    },
    PHP: {
        code: 'PHP',
        symbol: '₱',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₱[amount]',
    },
    PKR: {
        code: 'PKR',
        symbol: '₨',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₨[amount]',
    },
    PLN: {
        code: 'PLN',
        symbol: 'zł',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] zł',
    },
    PYG: {
        code: 'PYG',
        symbol: '₲',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '₲[amount]',
    },
    QAR: {
        code: 'QAR',
        symbol: '﷼',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '﷼[amount]',
    },
    RON: {
        code: 'RON',
        symbol: 'L',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '[amount] L',
    },
    RSD: {
        code: 'RSD',
        symbol: 'Дин.',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '[amount] Дин.',
    },
    RUB: {
        code: 'RUB',
        symbol: '₽',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] ₽',
    },
    RWF: {
        code: 'RWF',
        symbol: 'RWF',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: 'RWF[amount]',
    },
    SAR: {
        code: 'SAR',
        symbol: '﷼',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '﷼[amount]',
    },
    SBD: {
        code: 'SBD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    SCR: {
        code: 'SCR',
        symbol: '₨',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₨[amount]',
    },
    SDD: {
        code: 'SDD',
        symbol: 'LSd',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] LSd',
    },
    SDG: {
        code: 'SDG',
        symbol: '£‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '£‏[amount]',
    },
    SEK: {
        code: 'SEK',
        symbol: 'kr',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '[amount] kr',
    },
    SGD: {
        code: 'SGD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    SHP: {
        code: 'SHP',
        symbol: '£',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '£[amount]',
    },
    SLL: {
        code: 'SLL',
        symbol: 'Le',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'Le[amount]',
    },
    SOS: {
        code: 'SOS',
        symbol: 'S',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'S[amount]',
    },
    SRD: {
        code: 'SRD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    STD: {
        code: 'STD',
        symbol: 'Db',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'Db[amount]',
    },
    SVC: {
        code: 'SVC',
        symbol: '₡',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₡[amount]',
    },
    SYP: {
        code: 'SYP',
        symbol: '£',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '£[amount]',
    },
    SZL: {
        code: 'SZL',
        symbol: 'E',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'E[amount]',
    },
    THB: {
        code: 'THB',
        symbol: '฿',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '฿[amount]',
    },
    TJS: {
        code: 'TJS',
        symbol: 'TJS',
        decimal_mark: ';',
        thousands_separator: ' ',
        format: '[amount] TJS',
    },
    TMT: {
        code: 'TMT',
        symbol: 'm',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] m',
    },
    TND: {
        code: 'TND',
        symbol: 'د.ت.‏',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'د.ت.‏[amount]',
    },
    TOP: {
        code: 'TOP',
        symbol: 'T$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'T$[amount]',
    },
    TRY: {
        code: 'TRY',
        symbol: '₺',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '₺[amount]',
    },
    TTD: {
        code: 'TTD',
        symbol: 'TT$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'TT$[amount]',
    },
    TVD: {
        code: 'TVD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    TWD: {
        code: 'TWD',
        symbol: 'NT$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'NT$[amount]',
    },
    TZS: {
        code: 'TZS',
        symbol: 'TSh',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'TSh[amount]',
    },
    UAH: {
        code: 'UAH',
        symbol: '₴',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] ₴',
    },
    UGX: {
        code: 'UGX',
        symbol: 'USh',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'USh[amount]',
    },
    UYU: {
        code: 'UYU',
        symbol: '$U',
        decimal_mark: ',',
        thousands_separator: '.',
        format: '$U[amount]',
    },
    UZS: {
        code: 'UZS',
        symbol: 'сўм',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] сўм',
    },
    VEB: {
        code: 'VEB',
        symbol: 'Bs.',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'Bs.[amount]',
    },
    VEF: {
        code: 'VEF',
        symbol: 'Bs. F.',
        decimal_mark: ',',
        thousands_separator: '.',
        format: 'Bs. F.[amount]',
    },
    VND: {
        code: 'VND',
        symbol: '₫',
        decimal_mark: '.',
        thousands_separator: '.',
        format: '[amount] ₫',
    },
    VUV: {
        code: 'VUV',
        symbol: 'VT',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] VT',
    },
    WST: {
        code: 'WST',
        symbol: 'WS$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'WS$[amount]',
    },
    XAF: {
        code: 'XAF',
        symbol: 'F',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] F',
    },
    XCD: {
        code: 'XCD',
        symbol: '$',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '$[amount]',
    },
    XBT: {
        code: 'XBT',
        symbol: 'Ƀ',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] Ƀ',
    },
    XOF: {
        code: 'XOF',
        symbol: 'F',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: '[amount] F',
    },
    XPF: {
        code: 'XPF',
        symbol: 'F',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '[amount] F',
    },
    YER: {
        code: 'YER',
        symbol: '﷼',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '﷼[amount]',
    },
    ZAR: {
        code: 'ZAR',
        symbol: 'R',
        decimal_mark: ',',
        thousands_separator: ' ',
        format: 'R[amount]',
    },
    ZMW: {
        code: 'ZMW',
        symbol: 'ZK',
        decimal_mark: '.',
        thousands_separator: ',',
        format: 'ZK[amount]',
    },
    WON: {
        code: 'WON',
        symbol: '₩',
        decimal_mark: '.',
        thousands_separator: ',',
        format: '₩[amount]',
    },
}

export const currencyCodeList = Object.keys(currencies)
