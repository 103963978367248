import { SvgIcon } from '@mui/material'

const BoxIcon = () => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5 15V5L10 0.5L18.5 5V15L10 19.5L1.5 15ZM9.24991 17.4057L3 14.0969V6.61673L9.34366 10H9.24991V17.4057ZM10.7499 17.4057L17 14.0969V6.61664L10.6562 10H10.7499V17.4057ZM16.0718 5.4117L9.99991 8.65002L3.92815 5.41175L10 2.19724L16.0718 5.4117Z"
            fill="black"
        />
    </SvgIcon>
)

export default BoxIcon
