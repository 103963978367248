function path(root, sublink) {
    return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
    passwordUpdated: path(ROOTS_AUTH, '/password-updated'),
}

/**
 * @typedef {Object} PATH_DASHBOARD
 * @property {string} rootWithoutId
 * @property {string} global_amazon_mcf_redirect
 * @property {string} root
 * @property {Object} brands
 * @property {string} brands.root
 * @property {string} brands.create
 * @property {Object} products
 * @property {string} products.root
 * @property {string} products.variants
 * @property {Object} bundles
 * @property {string} bundles.root
 * @property {Object} offers
 * @property {string} offers.root
 * @property {function(offerId: string): string} offers.edit
 * @property {function(offerId: string, upsellId: string): string} offers.upsells
 * @property {Object} shipping
 * @property {string} shipping.root
 * @property {Object} shipping.warehouses
 * @property {string} shipping.warehouses.root
 * @property {Object} users
 * @property {string} users.root
 * @property {Object} settings
 * @property {string} settings.root
 * @property {Object} settings.brand
 * @property {string} settings.brand.root
 * @property {Object} settings.taxes
 * @property {string} settings.taxes.root
 * @property {Object} settings.payment
 * @property {string} settings.payment.root
 * @property {string} settings.payment.stripe
 * @property {Object} settings.currencies
 * @property {string} settings.currencies.root
 * @property {Object} settings.fulfillment
 * @property {string} settings.fulfillment.root
 * @property {string} settings.fulfillment.amazon_mcf_redirect
 * @property {Object} settings.email
 * @property {string} settings.email.root
 * @property {Object} settings.supportProviders
 * @property {string} settings.supportProviders.root
 * @property {Object} settings.customApps
 * @property {string} settings.customApps.root
 * @property {Object} settings.integrations
 * @property {string} settings.integrations.root
 * @property {Object} settings.supportSettings
 * @property {string} settings.supportSettings.root
 * @property {Object} orders
 * @property {string} orders.root
 * @property {Object} discounts
 * @property {Object} discounts.root
 * @property {function(id: string): string} orders.disputes
 * @property {Object} performance
 * @property {string} performance.root
 * @property {Object} mptools
 * @property {Object} mptools.domains
 * @property {string} mptools.domains.root
 * @property {Object} mptools.externalSubdomains
 * @property {string} mptools.externalSubdomains.root
 * @property {Object} mptools.migrationTool
 * @property {string} mptools.migrationTool.root
 */

/**
 * Generate Dynamic Dashboard paths based on brandId
 * @param {string|number} brandId - The brand id
 * @returns {PATH_DASHBOARD} The generated paths
 */
function generateDashboardPaths(brandId = 'brand-id') {
    const ROOTS_DASHBOARD = `/${brandId}/`
    const DASHBOARD_BRANDS = path(ROOTS_DASHBOARD, 'brands')
    const DASHBOARD_SHIPPING = path(ROOTS_DASHBOARD, 'shipping')
    const DASHBOARD_SETTINGS = path(ROOTS_DASHBOARD, 'settings')
    const DASHBOARD_MPTOOLS = path(ROOTS_DASHBOARD, 'mp-tools')

    return {
        rootWithoutId: '/',
        global_amazon_mcf_redirect: path('/amazon_mcf', '/redirect'),
        root: ROOTS_DASHBOARD,
        brands: {
            root: path(ROOTS_DASHBOARD, 'brands'),
            create: path(DASHBOARD_BRANDS, '/create'),
        },
        products: {
            root: path(ROOTS_DASHBOARD, 'products'),
            variants: path(ROOTS_DASHBOARD, 'products/variants'),
        },
        bundles: {
            root: path(ROOTS_DASHBOARD, 'bundles'),
        },
        offers: {
            root: path(ROOTS_DASHBOARD, 'offers'),
            edit: (offerId) => `${path(ROOTS_DASHBOARD, 'offers')}/${offerId}`,
            upsells: (offerId, upsellId) =>
                `${path(ROOTS_DASHBOARD, 'offers')}/${offerId}/upsells/${upsellId}`,
        },
        shipping: {
            root: DASHBOARD_SHIPPING,
            warehouses: {
                root: path(DASHBOARD_SHIPPING, '/warehouses'),
            },
        },
        users: {
            root: path(ROOTS_DASHBOARD, 'users'),
        },
        settings: {
            root: DASHBOARD_SETTINGS,
            brand: {
                root: path(DASHBOARD_SETTINGS, '/brand'),
            },
            taxes: {
                root: path(DASHBOARD_SETTINGS, '/taxes'),
            },
            payment: {
                root: path(DASHBOARD_SETTINGS, '/payment-gateways'),
                stripe: path(DASHBOARD_SETTINGS, '/payment-gateways/stripe-setup'),
            },
            currencies: {
                root: path(DASHBOARD_SETTINGS, '/currencies'),
            },
            fulfillment: {
                root: path(DASHBOARD_SETTINGS, '/fulfillment'),
                amazon_mcf_redirect: path(DASHBOARD_SETTINGS, '/fulfillment/amazon_mcf/redirect'),
            },
            email: {
                root: path(DASHBOARD_SETTINGS, '/email-providers'),
            },
            supportProviders: {
                root: path(DASHBOARD_SETTINGS, '/support-providers'),
            },
            integrations: {
                root: path(DASHBOARD_SETTINGS, '/integrations'),
            },
            customApps: {
                root: path(DASHBOARD_SETTINGS, '/custom-app'),
            },
            supportSettings: {
                root: path(DASHBOARD_SETTINGS, '/support'),
            },
        },
        orders: {
            root: path(ROOTS_DASHBOARD, 'orders'),
            disputes: (id) => path(ROOTS_DASHBOARD, `orders/${id}/disputes`),
        },
        discounts: {
            root: path(ROOTS_DASHBOARD, 'discounts'),
        },
        performance: {
            root: path(ROOTS_DASHBOARD, 'performance'),
        },
        payments: {
            root: path(ROOTS_DASHBOARD, 'payments'),
        },
        mptools: {
            root: DASHBOARD_MPTOOLS,
            domains: {
                root: path(DASHBOARD_MPTOOLS, '/domains'),
            },
            externalSubdomains: {
                root: path(DASHBOARD_MPTOOLS, '/external-subdomains'),
            },
            migrationTool: {
                root: path(DASHBOARD_MPTOOLS, '/migration-tool'),
            },
        },
    }
}

// eslint-disable-next-line import/no-mutable-exports
let PATH_DASHBOARD = generateDashboardPaths()

export function updatePaths(newBrandId) {
    PATH_DASHBOARD = generateDashboardPaths(newBrandId)
}

export { PATH_DASHBOARD }
