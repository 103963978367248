import useAuth from '@/hooks/useAuth'
import { INTERCOM } from '@/utils/enums/featureFlags'
import useFeatureFlags from '@/hooks/useFeatureFlags'

const useZendesk = () => {
    const { user } = useAuth()
    const { isFFEnabled } = useFeatureFlags()

    const hasBrandRole = !!user?.roles?.find((role) => role?.toLowerCase()?.startsWith('brand'))
    const hasGURole =
        user?.is_superuser || !!user?.roles?.find((role) => role?.toLowerCase()?.startsWith('gu'))

    const getChatAPIKey = () => {
        // Disable Zendesk when Intercom is enabled
        if (isFFEnabled(INTERCOM)) {
            return null
        }

        if (hasGURole) {
            return process.env.NEXT_PUBLIC_GU_USERS_ZENDESK_KEY
        }

        if (hasBrandRole) {
            return process.env.NEXT_PUBLIC_BRAND_USERS_ZENDESK_KEY
        }

        return null
    }

    return {
        getChatAPIKey,
    }
}

export default useZendesk
