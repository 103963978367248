import PayPalLogo from '@/components/icons/PayPalLogo'
import StripeLogo from '@/components/icons/StripeLogo'

export const PROVIDERS = {
    paypal: {
        label: 'PayPal',
        type: 'paypal',
        link: 'https://www.paypal.com/',
        loginUrl: 'https://www.paypal.com/signin',
    },
    stripe: {
        label: 'Stripe',
        type: 'stripe',
        link: 'https://stripe.com/',
        loginUrl: 'https://dashboard.stripe.com/login',
    },
}

export const PROVIDER_LOGOS = {
    [PROVIDERS.paypal.type]: <PayPalLogo sx={{ width: 130, height: 32 }} />,
    [PROVIDERS.stripe.type]: <StripeLogo sx={{ width: 90, height: 32 }} />,
}

export const PROVIDER_CONFIGURATION = {
    ACCOUNT: 'account',
    ACCOUNT_INCOMPLETE: 'account_incomplete',
    MERCHANT_ID: 'merchant_id',
    SELLER_NONCE: 'seller_nonce',
    AUTH_CODE: 'auth_code',
    SHARED_ID: 'shared_id',
    NEEDS_PAYMENT_METHOD: 'needs_payment_method',
    PAYOUT_FAILED: 'payout_failed',
}

export const getPaymentProviderByTypeAndKey = (providers, accountType, key) =>
    providers.filter(
        ({ configurations, type }) =>
            accountType === type && configurations.find((config) => config.key === key),
    )
