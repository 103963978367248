import { api } from '../apiSlice'
import TAGS from '../cacheTags'

const extendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllPaymentProviders: build.query({
            query: () => ({
                url: `payments/`,
            }),
            providesTags: [TAGS.paymentsProviders],
        }),
        createPaymentProvider: build.mutation({
            query: (data) => ({
                url: `payments/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.paymentsConfig],
        }),

        createProviderConfig: build.mutation({
            query: ({ providerId, ...data }) => ({
                url: `payments/${providerId}/configurations/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.paymentsConfig, TAGS.paymentsProviders],
        }),
        updateProviderConfig: build.mutation({
            query: ({ providerId, configId, ...data }) => ({
                url: `payments/${providerId}/configurations/${configId}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [TAGS.paymentsConfig, TAGS.paymentsProviders],
        }),

        getBrandPaymentMethods: build.query({
            query: () => ({
                url: `payments/payment-methods/`,
                method: 'GET',
            }),
            providesTags: [TAGS.brandPaymentMethods],
        }),
        updateBrandPaymentMethod: build.mutation({
            query: ({ brand_payment_method_id, ...data }) => ({
                url: `payments/payment-methods/${brand_payment_method_id}/`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: [TAGS.paymentsConfig, TAGS.paymentsProviders],
        }),

        stripeOnboarding: build.mutation({
            query: (data) => ({
                url: `stripe/accounts-onboarding/`,
                method: 'POST',
                body: {
                    ...data,
                    'collection_options[fields]': 'eventually_due',
                    'collection_options[future_requirements]': 'include',
                },
            }),
            invalidatesTags: [TAGS.paymentsConfig],
        }),
        paypalOnboarding: build.mutation({
            query: (data) => ({
                url: `paypal/v2/customer/partner-referrals`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.paymentsConfig, TAGS.paymentsProviders],
        }),
        paypalAccountStatus: build.query({
            query: ({ provider_id }) => ({
                url: `paypal/${provider_id}/status`,
            }),
            providesTags: [TAGS.paymentsProviders],
        }),
        getSupportedPayments: build.query({
            query: () => ({
                url: `payments/regions/`,
            }),
            providesTags: [TAGS.supportedPayments],
        }),
    }),
})

export const {
    useGetBrandPaymentMethodsQuery,
    useUpdateBrandPaymentMethodMutation,
    useStripeOnboardingMutation,
    usePaypalOnboardingMutation,
    useCreatePaymentProviderMutation,
    useCreateProviderConfigMutation,
    useUpdateProviderConfigMutation,
    useGetAllPaymentProvidersQuery,
    usePaypalAccountStatusQuery,
    useGetSupportedPaymentsQuery,
} = extendedApi
