import { createSlice } from '@reduxjs/toolkit'
import { api } from '../apiSlice'
import TAGS from '../cacheTags'

const extendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllBrands: build.query({
            query: () => `brands/`,
            providesTags: [TAGS.brands],
        }),
        getBrand: build.query({
            query: ({ brand_id }) => ({
                url: `brands/${brand_id}/`,
            }),
            providesTags: [TAGS.brand],
        }),
        createBrand: build.mutation({
            query: (data) => ({
                url: `brands/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [TAGS.brand, TAGS.brands],
        }),
        updateBrand: build.mutation({
            query: ({ id, ...data }) => ({
                url: `brands/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [TAGS.brand, TAGS.brands],
        }),

        createBrandMedia: build.mutation({
            query: ({ id, data }) => ({
                url: `brands/${id}/media/`,
                method: 'POST',
                body: data,
                format: 'multipart',
            }),
            invalidatesTags: [TAGS.brand, TAGS.brands],
        }),
        deleteBrandMedia: build.mutation({
            query: ({ id, mediaId }) => ({
                url: `brands/${id}/media/${mediaId}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAGS.brand, TAGS.brands],
        }),
        listRegisteredCountries: build.query({
            query: ({ brand_id }) => ({
                url: `brands/${brand_id}/registered-countries/`,
            }),
            providesTags: [TAGS.brand],
        }),

        // currency-overrides
        getBrandCurrencyOverrides: build.query({
            query: ({ brand }) => `brands/${brand}/currency-overrides/`,
            providesTags: [TAGS.brandCurrencyOverrides],
            transformResponse: (overrides) => overrides?.results || [],
        }),
        createBrandCurrencyOverride: build.mutation({
            query: ({ brand, ...data }) => ({
                url: `brands/${brand}/currency-overrides/`,
                method: 'POST',
                body: { brand, ...data },
            }),
            invalidatesTags: [TAGS.brandCurrencyOverrides],
        }),
        updateBrandCurrencyOverride: build.mutation({
            query: ({ brand, id, ...data }) => ({
                url: `brands/${brand}/currency-overrides/${id}/`,
                method: 'PATCH',
                body: { brand, ...data },
            }),
            invalidatesTags: [TAGS.brandCurrencyOverrides],
        }),
    }),
})

const brandSlice = createSlice({
    name: 'brand',
    initialState: {
        currentBrand: null,
    },
    reducers: {
        setCurrentBrand(state, action) {
            // eslint-disable-next-line no-param-reassign
            state.currentBrand = action.payload
        },
    },
})

export const {
    useGetAllBrandsQuery,
    useGetBrandQuery,
    useUpdateBrandMutation,
    useCreateBrandMutation,

    useCreateBrandMediaMutation,
    useDeleteBrandMediaMutation,

    useListRegisteredCountriesQuery,

    useGetBrandCurrencyOverridesQuery,
    useCreateBrandCurrencyOverrideMutation,
    useUpdateBrandCurrencyOverrideMutation,
} = extendedApi
export const { setCurrentBrand } = brandSlice.actions
export const { reducer } = brandSlice
