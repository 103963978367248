import { useEffect } from 'react'
import { PATH_DASHBOARD } from '@/routes/paths'
import BoxCircularProgress from '@/components/common/BoxCircularProgress'
import useBrandSelect from '@/hooks/useBrandSelect'
import useAuth from '@/hooks/useAuth'

const Home = () => {
    const { user, isFetching } = useAuth({ middleware: 'auth' })
    const { currentBrand } = useBrandSelect()

    useEffect(() => {
        if (isFetching || !user) {
            return
        }

        if (currentBrand) {
            window.location.pathname = PATH_DASHBOARD.performance.root
        }
    }, [currentBrand, user, isFetching])

    return <BoxCircularProgress />
}

export default Home
