import { SvgIcon } from '@mui/material'

const FilterIcon = ({ sx }) => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={sx}
    >
        <path
            fill="white"
            d="M12.081 17.7685H7.91898V12.8333C7.91898 12.6158 7.82214 12.4095 7.65476 12.2706L2.23148 7.7677V2.23148H17.7685V7.7677L12.3452 12.2706C12.1779 12.4095 12.081 12.6158 12.081 12.8333V17.7685Z"
            stroke="black"
            strokeWidth="1.46295"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SvgIcon>
)

export default FilterIcon
