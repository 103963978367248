import { SvgIcon } from '@mui/material'
import * as React from 'react'

const User = () => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 9.5C11.933 9.5 13.5 7.933 13.5 6C13.5 4.067 11.933 2.5 10 2.5C8.067 2.5 6.5 4.067 6.5 6C6.5 7.933 8.067 9.5 10 9.5ZM12 6C12 7.10457 11.1046 8 10 8C8.89543 8 8 7.10457 8 6C8 4.89543 8.89543 4 10 4C11.1046 4 12 4.89543 12 6Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 10.5C13.6284 10.5 16.6551 13.0766 17.35 16.5C17.4484 16.9847 17.5 17.4863 17.5 18H2.5C2.5 17.4863 2.55165 16.9847 2.65003 16.5C3.34493 13.0766 6.37156 10.5 10 10.5ZM10 12C12.7958 12 15.1449 13.9122 15.811 16.5H4.18903C4.85509 13.9122 7.20424 12 10 12Z"
            fill="black"
        />
    </SvgIcon>
)

export default User
