import { Box, CircularProgress } from '@mui/material'

const BoxCircularProgress = ({ sx }) => (
    <Box
        sx={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx,
        }}
    >
        <CircularProgress />
    </Box>
)

export default BoxCircularProgress
