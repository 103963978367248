import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { PATH_AUTH } from '@/routes/paths'
import { invalidateStorage } from '@/utils/browser-utils'
import { FORBIDDEN, UNAUTHORIZED } from '@/utils/enums/responseStatuses'
import UNKNOWN_BRAND from '@/utils/enums/responseCodes'
import { store } from '@/redux/store'

const handleResponseError = (error) => {
    if (error.status === UNAUTHORIZED && window.localStorage.getItem('auth_token')) {
        invalidateStorage()
        window.location.pathname = PATH_AUTH.login
    }

    if (error.status === FORBIDDEN && error.data?.code === UNKNOWN_BRAND) {
        window.location.reload()
    }
}

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.API_BASE_URL,
    prepareHeaders: (headers) => {
        if (window.localStorage.getItem('auth_token')) {
            headers.set('Authorization', `Token ${window.localStorage.getItem('auth_token')}`)
        }

        const brandId = store?.getState()?.brandSlice?.currentBrand?.id
        if (brandId) {
            headers.set('X-Brand-ID', brandId)
        }
        return headers
    },
})

const baseQueryWithAuthCheck = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)

    if (!result?.error) {
        return result
    }

    handleResponseError(result.error)

    return result
}

export default baseQueryWithAuthCheck
