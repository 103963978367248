import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import { api } from './apiSlice'
import { reducer as bundleSlice } from './slices/bundleSlice'
import { reducer as userSlice } from './slices/userSlice'
import { reducer as offerSlice } from './slices/offerSlice'
import { reducer as brandReducer } from './slices/brandSlice'
import { reducer as featureFlagReducer } from './slices/featureFlagSlice'
import { ordersReducer } from './slices/ordersSlice'
import { reducer as taxRegionsSlice } from './slices/taxRegionsSlice'
import selectBrandMiddleware from '@/redux/middleware/selectBrandMiddleware'

// eslint-disable-next-line
let store

export const makeStore = () => {
    store = configureStore({
        reducer: {
            [api.reducerPath]: api.reducer,
            userSlice,
            bundleSlice,
            offerSlice,
            brandSlice: brandReducer,
            featureFlagSlice: featureFlagReducer,
            orderSlice: ordersReducer,
            taxRegionsSlice,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat([api.middleware, selectBrandMiddleware]),
    })
    return store
}

export const wrapper = createWrapper(makeStore, { debug: true })

export { store }
