import '../styles/globals.css'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { SnackbarProvider } from 'notistack'
import Head from 'next/head'
import { StyledEngineProvider } from '@mui/material'
import Theme from '@/theme'
import { wrapper } from '@/redux/store'
import Home from '@/pages/index'
import useRouteAccess from '@/hooks/useRouteAccess'
import BrandLayout from '@/layouts/dashboard/brand'

const MyApp = ({ Component, pageProps, router }) => {
    const { pathname } = router
    const { canAccessRoute } = useRouteAccess()

    const ComponentToRender = canAccessRoute() ? Component : Home

    const getLayout = pathname.startsWith('/[brandId]/') ? BrandLayout : (page) => page

    return (
        <>
            <Head>
                <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
                <link rel="manifest" href="/favicon/site.webmanifest" />
                <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#f03d26" />
                <meta name="msapplication-TileColor" content="#f03d26" />
                <meta name="theme-color" content="#ffffff" />
            </Head>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={Theme}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <SnackbarProvider
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {getLayout(<ComponentToRender {...pageProps} />)}
                        </SnackbarProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    )
}

export default wrapper.withRedux(MyApp)
