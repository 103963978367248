import { SvgIcon } from '@mui/material'
import * as React from 'react'

const Clipboard = () => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 6H7.5V3H12.5V6ZM14 1.5H6V4.5H2.5V18.5H17.5V4.5H14V1.5ZM14 6.1V7.5H6V6.1H4.1V16.9H15.9V6.1H14ZM6 9.5V11H11.5V9.5H6ZM6 14.5V13H14V14.5H6Z"
            fill="black"
        />
    </SvgIcon>
)

export default Clipboard
