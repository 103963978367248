import { useEffect } from 'react'
import { useRouter } from 'next/router'
import {
    useGetCurrentUserQuery,
    useLogInMutation,
    useLogOutMutation,
} from '@/redux/slices/profileSlice'
import { PATH_AUTH } from '@/routes/paths'
import { invalidateStorage } from '@/utils/browser-utils'

const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
    const router = useRouter()

    const { data: user, error, isLoading, isFetching, refetch } = useGetCurrentUserQuery()
    const [logIn, { isLoading: isLoggingIn }] = useLogInMutation()
    const [logOut, { isLoading: isLoggingOut }] = useLogOutMutation()

    const login = async (email, password, setErrors) => {
        setErrors([])
        invalidateStorage()

        try {
            const response = await logIn({ email, password }).unwrap()
            window.localStorage.setItem('auth_token', response.token)
            refetch()
        } catch (err) {
            if (err.status !== 400) {
                throw err
            }

            const { status_code, ...validationErrors } = err.data

            if (validationErrors?.detail) {
                validationErrors.login = [validationErrors.detail]
            }

            setErrors(validationErrors)
        }
    }

    const logout = async () => {
        if (!error) {
            await logOut()
            invalidateStorage()
            refetch()
        }

        if (middleware === 'auth') {
            const login_url = new URL(`${window.location.origin}${PATH_AUTH.login}`)
            login_url.searchParams.append('redirect_url', window.location.href)
            window.location.href = login_url.toString()
        }
    }

    useEffect(() => {
        if (middleware === 'guest' && redirectIfAuthenticated && user) {
            if (router.query.redirect_url) {
                window.location.href = router?.query?.redirect_url
            } else {
                router.push(redirectIfAuthenticated)
            }
        }

        if (middleware === 'auth' && error) {
            logout()
        }
    }, [user, error])

    return {
        user,
        login,
        logout,
        isLoading,
        isFetching,
        isLoggingIn,
        isLoggingOut,
    }
}

export default useAuth
