import { SvgIcon } from '@mui/material'
import * as React from 'react'

const StripeLogo = (props) => {
    const { sx = {}, ...restProps } = props

    return (
        <SvgIcon sx={sx} {...restProps} viewBox="0 0 50 21">
            <g clipPath="url(#clip0_2001_21097)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.9999 10.8506C49.9999 7.26186 48.2776 4.43008 44.986 4.43008C41.6804 4.43008 39.6804 7.26186 39.6804 10.8226C39.6804 15.0422 42.0415 17.1731 45.4304 17.1731C47.0832 17.1731 48.3332 16.7946 49.2776 16.2619V13.4581C48.3332 13.9348 47.2499 14.2291 45.8749 14.2291C44.5276 14.2291 43.3332 13.7525 43.1804 12.0983H49.9721C49.9721 11.9161 49.9999 11.1871 49.9999 10.8506ZM43.1388 9.51887C43.1388 7.93476 44.0971 7.27588 44.9721 7.27588C45.8193 7.27588 46.7221 7.93476 46.7221 9.51887H43.1388Z"
                    fill="#635BFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.3193 4.43008C32.9582 4.43008 32.0832 5.07494 31.5971 5.52354L31.4165 4.65438H28.361V21.0002L31.8332 20.2572L31.8471 16.2899C32.3471 16.6544 33.0832 17.1731 34.3054 17.1731C36.7915 17.1731 39.0554 15.1544 39.0554 10.7105C39.0415 6.64504 36.7498 4.43008 34.3193 4.43008ZM33.486 14.089C32.6665 14.089 32.1804 13.7946 31.8471 13.4301L31.8332 8.22915C32.1943 7.82261 32.6943 7.54223 33.486 7.54223C34.7498 7.54223 35.6248 8.97214 35.6248 10.8086C35.6248 12.6871 34.7637 14.089 33.486 14.089Z"
                    fill="#635BFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.5835 3.6028L27.0696 2.84579V0L23.5835 0.742991V3.6028Z"
                    fill="#635BFF"
                />
                <path d="M27.0696 4.66827H23.5835V16.9346H27.0696V4.66827Z" fill="#635BFF" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.8471 5.70551L19.6249 4.66813H16.6249V16.9345H20.0971V8.6214C20.9165 7.54196 22.3054 7.73822 22.736 7.89243V4.66813C22.2915 4.4999 20.6665 4.19149 19.8471 5.70551Z"
                    fill="#635BFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.9028 1.62643L9.51389 2.35541L9.5 13.5844C9.5 15.6591 11.0417 17.1872 13.0972 17.1872C14.2361 17.1872 15.0694 16.9769 15.5278 16.7246V13.8788C15.0833 14.061 12.8889 14.7059 12.8889 12.6311V7.65447H15.5278V4.66849H12.8889L12.9028 1.62643Z"
                    fill="#635BFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.51389 8.22915C3.51389 7.68242 3.95833 7.47214 4.69444 7.47214C5.75 7.47214 7.08333 7.79457 8.13889 8.36934V5.07494C6.98611 4.61233 5.84722 4.43008 4.69444 4.43008C1.875 4.43008 0 5.91607 0 8.39737C0 12.2665 5.27778 11.6497 5.27778 13.3179C5.27778 13.9628 4.72222 14.1731 3.94444 14.1731C2.79167 14.1731 1.31944 13.6964 0.152778 13.0516V16.388C1.44444 16.9488 2.75 17.1871 3.94444 17.1871C6.83333 17.1871 8.81944 15.7432 8.81944 13.2338C8.80556 9.05625 3.51389 9.79924 3.51389 8.22915Z"
                    fill="#635BFF"
                />
            </g>
            <defs>
                <clipPath id="clip0_2001_21097">
                    <rect width="50" height="21" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default StripeLogo
