import { SvgIcon } from '@mui/material'

const Payments = ({ sx }) => (
    <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        sx={sx}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5 15V17H18.5V5H16.5V6.46295H17.037V15.537H4.96295V15H3.5Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.037 4.46295H2.96295V13.537H15.037V4.46295ZM1.5 3V15H16.5V3H1.5Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5 7.46295H8.5C8.2034 7.46295 7.96295 7.7034 7.96295 8V10C7.96295 10.2966 8.2034 10.537 8.5 10.537H9.5C9.7966 10.537 10.037 10.2966 10.037 10V8C10.037 7.7034 9.7966 7.46295 9.5 7.46295ZM8.5 6C7.39543 6 6.5 6.89543 6.5 8V10C6.5 11.1046 7.39543 12 8.5 12H9.5C10.6046 12 11.5 11.1046 11.5 10V8C11.5 6.89543 10.6046 6 9.5 6H8.5Z"
            fill="black"
        />
    </SvgIcon>
)

export default Payments
