import { SvgIcon } from '@mui/material'

const Performance = ({ sx }) => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={sx}
    >
        <path
            d="M15.8712 5.75H12.9998V4.25H18.2498V9.5H16.7498V6.99273L10.457 13.2855L7.275 10.1035L2.85558 14.523L1.79492 13.4623L7.275 7.98223L10.457 11.1642L15.8712 5.75Z"
            fill="black"
        />
    </SvgIcon>
)

export default Performance
