import { createTheme } from '@mui/material/styles'
import shadows, { customShadows } from './shadows'
import customBorders from '@/theme/borders'
import 'react-date-range/dist/theme/default.css'

const Index = createTheme({
    palette: {
        primary: {
            600: '#FDF1F2',
            light: '#FBC5BE',
            400: '#F68B7D',
            350: '#FF1D00',
            main: '#F03D26',
        },
        grey: {
            900: '#f9fafa',
            850: '#F2F5F6',
            800: '#F8F9FA',
            780: '#E6E6E6',
            750: '#B4C2C9',
            700: '#B4C2C9',
            600: '#f3f5f6',
            500: '#E6EBED',
            420: '#B3B3B3',
            400: '#9CA3AF',
            350: '#8f8f8f',
            320: '#666666',
            300: '#606060',
        },
        orange: {
            900: '#FFFCF8',
            800: '#FDE6B9',
            500: '#FACB78',
            300: '#F09F26',
        },
        green: {
            900: '#F9FDFA',
            600: '#F8FDF8',
            550: '#C6EDD1',
            500: '#8EDAA4',
            450: '#40C265',
            420: '#40C26533',
            400: '#8CDBA2',
            300: '#22c55e',
            290: '#258841',
            280: '#1B7039',
            250: '#ECF9F0',
        },
        customBlack: {
            400: '#202020',
        },
        red: {
            300: '#D32F2F',
            200: '#FAC5BF',
        },
        blue: {
            600: '#4E7482',
        },
        typography: {
            black: '#222222',
        },
        tremor: {
            gray: '#6b7280',
        },
    },
    typography: {
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    shadows,
    customShadows,
    customBorders,
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 'none',
                },
                columnHeader: {
                    background:
                        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1;',
                },
                columnSeparator: {
                    display: 'none',
                },
                cell: {
                    '&:focus-within': {
                        outline: 'none',
                    },
                    borerBottom: '1px solid #C2C9D1',
                },
                columnHeaderTitle: {
                    color: 'rgba(0, 0, 0, 0.5)',
                    fontSize: '12px',
                },
                columnHeaderDraggableContainer: {
                    '&:focus-within': {
                        outline: 0,
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-shrink': {
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontWeight: 500,
                    },
                },
                asterisk: {
                    color: '#F03D26',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#8f8f8f',
                },
            },
        },
    },
})

export default Index
