import { SvgIcon } from '@mui/material'
import * as React from 'react'

const Lan = () => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 1.5H13V7.5H10.75V9H16V12.5H18.5V18.5H12.5V12.5H14.5V10.5H5.5V12.5H7.5V18.5H1.5V12.5H4V9H9.25V7.5H7V1.5ZM8.5 3V6H11.5V3H8.5ZM3 17V14H6V17H3ZM14 14V17H17V14H14Z"
            fill="black"
        />
    </SvgIcon>
)

export default Lan
