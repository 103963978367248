import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { setFeatureFlags, useGetAllFeatureFlagsQuery } from '@/redux/slices/featureFlagSlice'

const useFeatureFlags = () => {
    const dispatch = useDispatch()
    const { isReady } = useRouter()
    const { data, isFetching } = useGetAllFeatureFlagsQuery()
    const featureFlags = useSelector((state) => state.featureFlagSlice.featureFlags)

    const isFFEnabled = (flag) => !!featureFlags?.[flag]

    // Initialize the featureFlags
    useEffect(() => {
        if (isFetching || !isReady || featureFlags) {
            return
        }

        dispatch(
            setFeatureFlags(
                data?.features?.reduce(
                    (acc, curr) => ({ ...acc, [curr.name]: curr.enabled }),
                    {},
                ) || {},
            ),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isReady])

    return {
        isFetching,
        featureFlags,
        isFFEnabled,
    }
}

export default useFeatureFlags
