import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setCurrentBrand, useGetAllBrandsQuery } from '@/redux/slices/brandSlice'

const useBrandSelect = () => {
    const dispatch = useDispatch()
    const { query, isReady } = useRouter()
    const { data: brands, isFetching } = useGetAllBrandsQuery()
    const currentBrand = useSelector((state) => state.brandSlice.currentBrand)

    // Initialize the currentBrand
    useEffect(() => {
        const brandId = query?.brandId || 0

        const brand =
            brands?.find(
                (b) =>
                    b.id === +brandId ||
                    b.slug.toLowerCase() === brandId?.toString()?.toLowerCase(),
            ) ||
            brands?.[0] ||
            {}

        if (isFetching || !isReady || currentBrand || !brand) {
            return
        }

        dispatch(setCurrentBrand(brand))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brands, query, isFetching, currentBrand, isReady])

    return {
        isFetching,
        currentBrand,
    }
}

export default useBrandSelect
