import moment from 'moment'
import { currencies } from '@/utils/enums/currencies'
import { getLongMonthName } from '@/utils/date-time-utils'

// ****** Date-Time Formatting ******
export const formatDate = (fullDateTime, format = 'L') =>
    moment.parseZone(fullDateTime).format(format)

export const formatTime = (fullDateTime) => moment.parseZone(fullDateTime).format('LT')

export const localeDateString = (date) => {
    if (!date) {
        return ''
    }

    return new Date(date).toLocaleDateString()
}

export const formatDateYearMonthDay = (date) => {
    const dt = new Date(date)
    const year = dt.getFullYear() // year YYYY
    const month = `${dt.getMonth() + 1}`.padStart(2, '0')
    const dayOfTheMonth = `${dt.getDate()}`.padStart(2, '0')
    return `${year}-${month}-${dayOfTheMonth}`
}

// Returns this format: MMMM D, YYYY hh:mmA
// Example: September 6, 2023, at 11:30pm
export const formatDateTimeLong = (data) => {
    const dt = new Date(data)
    return `${getLongMonthName(dt)} ${dt.getDate()}, ${dt.getFullYear()} at ${dt
        .toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
        })
        .toLowerCase()
        .replace(' ', '')}`
}

// Example: September 6, 2023
export const formatDateLong = (data) => {
    const dt = new Date(data)
    return `${getLongMonthName(dt)} ${dt.getDate()}, ${dt.getFullYear()}`
}

// ****** Number-Money Formatting ******
export const parseFloatFixed = (number, fixed = 2) => +parseFloat(number).toFixed(fixed)

export const getCurrencySymbol = (code) => currencies[code]?.symbol || '$'

// We use this to format money based on the currency format
export const formatMoney = ({
    amount,
    currency = 'USD',
    currencyFormat = null,
    fractionDigits = 2,
    hideSuffix = false,
}) => {
    const { format, thousands_separator, decimal_mark } =
        currencyFormat || currencies[currency] || currencies.USD
    const formattedAmount = parseFloatFixed(amount, fractionDigits).toLocaleString('en-US', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    })

    if (!format || hideSuffix) {
        return `${getCurrencySymbol(currency)}${formattedAmount}`
    }

    return format.replace(
        '[amount]',
        formattedAmount.replace(/,/gi, thousands_separator).replace(/\./gi, decimal_mark),
    )
}

// ****** String Formatting ******
export const commaSeparatedString = (array) => array.filter((s) => s).join(', ')

export const formatRegionAndPostalCode = (region, postalCode) =>
    region && postalCode ? [`${region} ${postalCode}`] : [region, postalCode]

export const formatAddress = ({
    address_line1,
    address_line2,
    city,
    country,
    region,
    postal_code,
}) =>
    commaSeparatedString([
        address_line1,
        address_line2,
        city,
        ...formatRegionAndPostalCode(region, postal_code),
        country,
    ])

export const titleCase = (word) => word.charAt(0).toUpperCase() + word.slice(1)

export const toTitleCase = (str) =>
    !str ? '' : str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase())

export const transformUnderscoreType = (type, includeAbbreviation = false) => {
    const words = type.split('_')

    const capitalizedWords = words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

    if (!includeAbbreviation) {
        return capitalizedWords
    }

    const abbreviation = words.map((word) => word.charAt(0).toUpperCase()).join('')

    return `${capitalizedWords} (${abbreviation})`
}
