import { api } from '../apiSlice'

const extendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCurrentUser: build.query({
            query: () => `accounts/profile/`,
        }),
        logIn: build.mutation({
            query: ({ email, password }) => ({
                url: 'accounts/login/',
                method: 'POST',
                body: { login: email, password },
            }),
        }),
        logOut: build.mutation({
            query: () => ({
                url: 'accounts/logout/',
                method: 'POST',
                body: { revoke_token: true },
            }),
        }),
        signUp: build.mutation({
            query: (data) => ({
                url: 'accounts/invitation-accept/',
                method: 'POST',
                body: data,
            }),
        }),
        profileDetails: build.mutation({
            query: (data) => ({
                url: `accounts/invitation-info/`,
                method: 'POST',
                body: data,
            }),
        }),
        resendEmailVerification: build.mutation({
            query: (data) => ({
                url: `accounts/resend-email-verification/`,
                method: 'POST',
                body: data,
            }),
        }),
        sendResetPasswordLink: build.mutation({
            query: ({ email }) => ({
                url: 'accounts/send-reset-password-link/',
                method: 'POST',
                body: { email },
            }),
        }),
        resetPassword: build.mutation({
            query: ({ userId: user_id, timestamp, signature, password }) => ({
                url: 'accounts/reset-password/',
                method: 'POST',
                body: { user_id, password, timestamp, signature },
            }),
        }),
    }),
})

export const {
    useGetCurrentUserQuery,
    useLogInMutation,
    useLogOutMutation,
    useSignUpMutation,
    useProfileDetailsMutation,
    useResendEmailVerificationMutation,
    useSendResetPasswordLinkMutation,
    useResetPasswordMutation,
} = extendedApi
