import { SvgIcon } from '@mui/material'

const Discount = ({ sx }) => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={sx}
    >
        <path
            d="M12.2257 6.64694L6.64636 12.2263L7.77304 13.3529L13.3524 7.77363L12.2257 6.64694Z"
            fill="black"
        />
        <path
            d="M7.7938 9.36781C8.67424 9.36781 9.38798 8.65407 9.38798 7.77363C9.38798 6.89318 8.67424 6.17944 7.7938 6.17944C6.91335 6.17944 6.19961 6.89318 6.19961 7.77363C6.19961 8.65407 6.91335 9.36781 7.7938 9.36781Z"
            fill="black"
        />
        <path
            d="M12.2257 13.8158C13.1061 13.8158 13.8199 13.102 13.8199 12.2216C13.8199 11.3411 13.1061 10.6274 12.2257 10.6274C11.3452 10.6274 10.6315 11.3411 10.6315 12.2216C10.6315 13.102 11.3452 13.8158 12.2257 13.8158Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8403 8.0363L15.1538 6.91794C15.4514 5.85627 15.1971 5.33796 15.0029 5.13739C14.8049 4.93295 14.2656 4.64083 13.1031 4.90384L12.0528 5.14146L11.4917 4.22226C10.8539 3.17715 10.2697 2.9997 10.0011 3.00012C9.73882 3.00054 9.19058 3.17255 8.62024 4.15508L8.03686 5.16011L6.91795 4.84634C5.85631 4.54863 5.33767 4.80302 5.13693 4.99741C4.93242 5.19544 4.64057 5.73446 4.90384 6.89526L5.142 7.94539L4.22321 8.50692C3.17733 9.14612 2.99962 9.73057 3 9.99913C3.00037 10.2612 3.17208 10.8095 4.15589 11.3812L5.16019 11.9648L4.84649 13.0832C4.54875 14.1447 4.80306 14.6628 4.99716 14.8632C5.19515 15.0676 5.73455 15.3597 6.89685 15.0968L7.94699 14.8593L8.50803 15.7782C9.14544 16.8223 9.72984 17.0003 9.9991 16.9999C10.2618 16.9996 10.81 16.8277 11.3801 15.8454L11.9634 14.8402L13.0824 15.154C14.1441 15.4517 14.6625 15.1974 14.863 15.0032C15.0673 14.8054 15.3594 14.2663 15.0964 13.1041L14.8589 12.0545L15.7771 11.4932C16.8227 10.8541 17.0004 10.2697 17 10.0012C16.9996 9.73925 16.828 9.19114 15.8446 8.61978L14.8403 8.0363ZM12.7721 3.44081C15.7172 2.77453 17.374 4.55475 16.5982 7.32279C19.1155 8.78534 19.1653 11.1801 16.5594 12.7731C17.2258 15.7176 15.4455 17.3744 12.6775 16.5983C11.2166 19.1156 8.81816 19.1648 7.22776 16.5598C4.28322 17.2258 2.62584 15.4461 3.40222 12.6781C0.884373 11.215 0.834563 8.81997 3.44098 7.22702C2.77357 4.28417 4.55436 2.62567 7.32296 3.40205C8.78387 0.885275 11.1811 0.834105 12.7721 3.44081Z"
            fill="black"
        />
    </SvgIcon>
)

export default Discount
