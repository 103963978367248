import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useAuth from '@/hooks/useAuth'
import usePermissions from '@/hooks/usePermissions'
import { PATH_DASHBOARD } from '@/routes/paths'
import useNavigation from '@/hooks/useNavigation'

const useRouteAccess = () => {
    const { isLoading } = useAuth()
    const { navConfig } = useNavigation()
    const { hasSomePermission } = usePermissions()
    const { pathname, asPath, push } = useRouter()

    const isDynamicRoute = pathname?.match(/\[.*]/)
    const route = navConfig
        .flatMap((n) => [n, ...(n.subroutes || [])])
        .find(
            (n) =>
                n.path !== PATH_DASHBOARD.root &&
                (isDynamicRoute
                    ? n.isSubroute && asPath.match(`^${n.path}`)
                    : pathname.match(`^${n.path}`)),
        )
    const parentRoute = navConfig.find((n) => n.subroutes?.find((s) => s.path === route?.path))

    const redirect = (path) => push(path, undefined, { shallow: true })

    const canAccessRoute = () => isLoading || hasSomePermission(route?.accessPermission)

    const checkSubrouteAccess = () => {
        if (hasSomePermission(route.accessPermission)) {
            return
        }

        if (hasSomePermission(parentRoute.accessPermission)) {
            redirect(parentRoute.path)
        } else {
            redirect(PATH_DASHBOARD.orders.root)
        }
    }

    const checkRouteAccess = () => {
        if (route.isSubroute) {
            checkSubrouteAccess()
        } else if (!hasSomePermission(route.accessPermission)) {
            redirect(PATH_DASHBOARD.orders.root)
        }
    }

    useEffect(() => {
        if (!isLoading && route) {
            checkRouteAccess()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route, isLoading])

    return {
        route,
        canAccessRoute,
    }
}

export default useRouteAccess
