import { createSlice } from '@reduxjs/toolkit'
import { api } from '../apiSlice'
import TAGS from '../cacheTags'

const extendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllFeatureFlags: build.query({
            query: () => `operations/features/`,
            providesTags: [TAGS.featureFlags],
        }),
    }),
})

const featureFlagSlice = createSlice({
    name: 'feature',
    initialState: {
        featureFlags: null,
    },
    reducers: {
        setFeatureFlags(state, action) {
            // eslint-disable-next-line no-param-reassign
            state.featureFlags = action.payload
        },
    },
})

export const { useGetAllFeatureFlagsQuery } = extendedApi
export const { setFeatureFlags } = featureFlagSlice.actions
export const { reducer } = featureFlagSlice
