import { SvgIcon } from '@mui/material'

const Crown = ({ sx }) => (
    <SvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={sx}
    >
        <path
            d="M7.8606 8.39746L10 3.36854L12.1394 8.39746C12.2384 8.63008 12.4504 8.79511 12.7002 8.83392C12.95 8.87272 13.2021 8.77979 13.367 8.58816L17.7685 3.47187V8.11111V17.7685H12.8125H7.1875H2.23148V8.11111V3.47187L6.63299 8.58816C6.79785 8.77979 7.04998 8.87272 7.29978 8.83392C7.54958 8.79511 7.76164 8.63008 7.8606 8.39746Z"
            stroke="black"
            strokeWidth="1.46295"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
        />
    </SvgIcon>
)

export default Crown
